function swap(image) {
    "use strict";
    var widthwindow1 = $(window).width();
    document.getElementById("image").src = image.href;
    if(widthwindow1 >= 1024){
        $('#image').elevateZoom({
            zoomType: "inner",
            cursor: "crosshair",
            zoomWindowFadeIn: 375,
            zoomWindowFadeOut: 375
        });
    };
};


jQuery('select.product-option').on('change',function() {
    var selected = jQuery(this).find('option:selected');
    // Compute new Price
    jQuery('#product_sku').val(selected.data('sku'));
});

jQuery('#fc-cart').on('submit',function() {

        if(jQuery('#product_sku').val() == '') {
            alert('Please select a Product Option.')
            return false;
        }

    return true;
});


jQuery('#contact-form').submit(function(e){
    var validated = true;
    var form = $(this);

    form.find('input[required],textarea[required]').each(function(){
        if( $(this).val() == '' ) {
            $(this).addClass('input-error');
            validated = false;
        }
        else {
            $(this).removeClass('input-error');
        }
    });

    console.log('Validated 1' + validated);


    $.ajax({
        url: '/theme/mailer/recaptcha.php',
        type: 'POST',
        data: form.serialize(),
        error: function() {
            alert('An error occured while processing the request. Please try again later.');
        },
        complete : function(data) {

            if(data.responseText == '') {
                validated = true;
                jQuery('#recaptcha-msg').html('');
            } else {
                validated = false;
                jQuery('#recaptcha-msg').html(data.responseText);

            }



            if(validated) {
                console.log('inside validated');

                $.ajax({
                    url: '/theme/mailer/mail.php',
                    type: 'POST',
                    data: form.serialize(),
                    error: function() {
                        alert('An error occured while processing the request. Please try again later.');
                    },
                    success: function(response) {
                        var data = jQuery.parseJSON(response);
                        //alert(data);
                        console.log(data);
                        if(data.success === true) {
                            jQuery('#success-form-msg').html(data.msg).show();
                        } else {
                            jQuery('#recaptcha-msg').html(data.msg);
                        }

                    }
                });

                console.log('below ajax');

            }

        }

    });



    console.log('below validated');

    e.preventDefault();
});